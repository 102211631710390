<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="세부추진사항 기준정보"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="grid.data"
          :gridHeight="grid.height"
          :editable="editable"
          :usePaging="false"
          :isExcelDown="false"
          :filtering="true"
          :isFullScreen="false"
          :columnSetting="false"
          selection='multiple'
          rowKey="saiSafetyHealthyDetailId"
          @rowDblclick="rowDblclick"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
              <c-btn v-if="editable" label="선택" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
        type: 'multiple',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'plantCd', },
          { index: 1, colName: 'saiSafetyHealthyId', },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'targetName',
            field: 'targetName',
            label: '안전보건 목표',
            align: 'left',
            sortable: true,
          },
          {
            name: 'detailName',
            field: 'detailName',
            label: '세부추진사항',
            align: 'left',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '해당부서',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'kpi',
            field: 'kpi',
            label: 'KPI성과지표',
            align: 'center',
            style: 'width:200px',
          },
        ],
        data: [],
        height: '600px',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.safetyHealthy.base.detail.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '세부추진사항을 선택하세요.', 
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>